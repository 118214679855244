import styled from 'styled-components'
import { colors } from '../style-guide/colors'

export const Container = styled.div`
  // background: orange;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    height: auto;
    overflow: hidden;
  }
`
export const Button = styled.button`
  height: 50px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: none;
  text-transform: inherit;
  background-color: ${({ background }) => background || 'rgb(40, 183, 193)'};
  border-radius: 50px;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin: 5px;
  border-color: transparent;
  font-size: 18px;
  font-weight: 600;
  &: hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
`
export const LoginButton = styled(Button)`
  background: #f3f3f3;
  border-color: pink;
  border-width: 2px;
  margin-top: 40px;
  margin-left: 20px;
`

export const Status = styled.div`
  color: white;
  width: 100px;
  font-weight: 700;
  padding: 10px;
  border-radius: 30px;
`

export const UserContainer = styled.div`
  // background: pink;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
  padding: 5px;
  background: #f4f4f4;
`
export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const UserImage = styled.div`
  width: 80px;
  height: 80px;
  background: white;
  background-image: ${({ photoURL }) => `url(${photoURL})`};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`

export const PageContent = styled.div`
  max-width: 1200px;
  margin: 0 80px;
  color: ${colors.charcoal};
  @media (max-width: 450px) {
    margin: 30px;
    margin-top: 70px;
  }
`