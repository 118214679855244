import React from 'react'

import styled from 'styled-components'
import { Status } from './elements'
import * as T from '../style-guide/typography'

const UserContainer = styled.div`
  // background: pink;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
  padding: 5px;
`
const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const UserImage = styled.div`
  width: 80px;
  height: 80px;
  margin: 10px;
  background: white;
  background-image: ${({ photoURL }) => `url(${photoURL})`};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`
export const User = ({ user }) => (
  <UserContainer>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserImage photoURL={user.photoURL} />
      <div>
        <T.Small>
          <div>
            {user.displayName}, {user.age}
          </div>
          {/* <div style={{ fontSize: '0.9em' }}>{user.currentLocation.city}</div> */}
        </T.Small>
      </div>
    </div>
    <div>
      <div
        style={{
          textAlign: 'end',
          marginRight: 10,
        }}
      >
        <T.Small style={{ color: 'lightgray' }}>
          {user.currentLocationLastUpdated}
        </T.Small>
      </div>
      <div>
        <T.Small>
          {user.profileHasBeenApproved ? (
            <StatusContainer>
              <Status
                style={{
                  background: 'rgb(40, 183, 193)',
                }}
              >
                Approved
              </Status>
              <div style={{ fontWeight: 600 }}>{user.uid}</div>
            </StatusContainer>
          ) : (
            <StatusContainer>
              <Status
                style={{
                  background: '#e91e63',
                }}
              >
                <div>Declined</div>
              </Status>
              <div style={{ fontWeight: 600 }}>{user.uid}</div>
            </StatusContainer>
          )}
        </T.Small>
      </div>
    </div>
  </UserContainer>
)
