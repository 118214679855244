import React from 'react'
import styled from 'styled-components'
import { PageContent } from './WebApp/elements'

export const Logo = styled.div`
  // width: 90vw;
  height: 40vh;
  width: 90vw;
  max-width: 900px;
  // max-width: 1000px;
  background-image: url(logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  transform: rotate(-10deg);
  @media (max-width: 450px) {
    // width: 90%;
    height: 410px;
  }
`

const Ul = styled.ul`
  margin-top: 10px;
`
const Li = styled.li`
  margin-top: 8px;
`

export const Volunteer = () => {
  return (
    <PageContent>
      <h1>Volunteer</h1>
      <p>Welcome!</p>
      <p>So cool that you're interested in volunteering!</p>
      <p>Things you could help with include:</p>
      <br />
      <Ul>
        <Li>Social media fun: Cute & Funny posts and/or videos and content informing about buddies</Li>
        <Li>Frontend development (React Native)</Li>
        <Li>Backend development (currently serverless with firebase, looking to switch so possibly postgres)</Li>
      </Ul>
      <br />

      <br />
      <h3>Social Media </h3>
      <Ul>
        <Li>
          <a
            href='https://www.facebook.com/theBuddiesApp'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </a>
        </Li>
        <Li>
          <a
            href='https://www.linkedin.com/company/buddies'
            target='_blank'
            rel='noopener noreferrer'
          >
            LinkedIn
          </a>
        </Li>
        <Li>
          <a
            href='https://www.instagram.com/the.buddies.app/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Instagram
          </a>
        </Li>

        <Li>
          <a
            href='https://youtu.be/mNTF_XFMWC4'
            target='_blank'
            rel='noopener noreferrer'
          >
            Promotional Video
          </a>
        </Li>
      </Ul>
      <br />
      <h2>Next steps </h2>
      <p>
        If you're interested checkout the <a href='/whatsdone'>resources</a>,
        create one example post and send it to the.buddies.app@gmail.com along
        with a paragraph on why you want to get involved in this
        project, a list the design tools and / or technologies you are familiar with  and let's get in action!
      </p>
    </PageContent>
  )
}
