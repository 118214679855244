import React from 'react'
import * as typography from '../style-guide/typography'
import { colors } from '../style-guide/colors'
import styled from 'styled-components'
import Video from './Video'

// same as Container from Home
const Container = styled.div`
  // background: orange;
  // margin-top: 70px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    height: auto;
    overflow: hidden;
  }
`

export const About = ({ children }) => {
  return (
    <Container>
      <typography.H2 style={{ marginTop: 50 }}>About</typography.H2>
      <typography.Regular>
        Today moving between cities and countries is easier than ever before,
        with many new people arriving in big cities every day. Most people tend
        to have a busy schedule and meeting new people can be tricky, so it
        often happens that in big metropolitan cities such as Berlin, New York
        and London people feel alone.
        <br />
        <br />
        So how do we meet new people?
        <ol>
          <li>Through Work.</li>
          <li>
            Groups with activities like martial arts, dancing or gym. On MeetUp
            people can also join a group. But what if the activity they are
            interested in is just for two people, for example tennis, table
            tennis or playing chess?
          </li>
          <li>
            Dating Apps. Many people use dating apps to meet new people and make
            friends even if a romantic relationship doesn’t work.
          </li>
        </ol>
        After making these observations we decided to build a social mobile app
        offering a friendly platform where people can meet each other, based on
        common interests and geographical proximity.
      </typography.Regular>
      <typography.H2 style={{ color: colors.pink }}>
        Buddies is a fun, respectful and safe platform for expats, locals and
        travelers, interested in creating meaningful friendly interactions.
      </typography.H2>
      <Video />
      <br />
      <br />
    </Container>
  )
}
