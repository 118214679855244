import React from 'react'
import styled from 'styled-components'
import * as T from './style-guide/typography'
import { StyledALink } from './elements'

const IconContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: 25px;
  height: 25px;
  &: hover {
    /* background: #e91e63; */
    filter: invert(1);
  }
`
const Icon = styled.img`
  height: 25px;
`

const StyledSpan = styled.span`
  &: hover {
    cursor: pointer;
    /* font-size: 25px; */
    color: #e91e63;
    font-weight: 800;
  }
`
const StyledH4 = styled(T.H4)`
  padding-left: 0;
  margin-right: 0;
  padding-right: 0;
  color: ${({ color }) => color || 'black'};

  @media (max-width: 700px) {
    margin: 5px;
  }
`
const FooterContainer = styled.div`
  background: linear-gradient(115deg, #f8ab58, #ff7949, #fc6383);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  padding-top: 50px;
  margin-top: 100px;
  padding-bottom: 30px;
`
const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 700px) {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
`
const SocialMediaContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: invert(1);
`
const SecondRow = styled(FirstRow)`
  @media (max-width: 700px) {
    margin-top: 30px;
  }
`
const ThirdRow = styled(SecondRow)``
const FourthRow = styled(SecondRow)`
  font-size: 14px;
  color: gray;
  margin-top: 20px;
`
const Ul = styled.ul`
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
`
const Li = styled.li`
  // position: relative;
  margin-right: 20px;
  font-size: 14px;
  color: white;
`
const A = styled.a`
  text-decoration: none;
  color: currentColor;
`
const SeparatingLine = styled.div`
  width: 90%;
  height: 1px;
  background: white;
  align-self: center;
`
const FlexSpaced = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`

export const Footer = () => {
  return (
    <FooterContainer>
      {/* <FirstRow>
        <img src='logo.svg' style={{ width: 300, marginBottom: 50 }} />
      </FirstRow> */}
      <FirstRow>
        <SocialMediaContainer>
          <a
            href='https://www.linkedin.com/company/buddies'
            target='_blank'
            rel='noopener noreferrer'
            style={{ margin: '10px' }}
          >
            <IconContainer>
              <Icon src='assets/linkedin_logo1.png' alt='linkedin logo' />
            </IconContainer>
          </a>
          <a
            href='https://www.facebook.com/theBuddiesApp/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ margin: '10px' }}
          >
            <IconContainer>
              <Icon src='assets/fb_logo.png' alt='facebook logo' />
            </IconContainer>
          </a>
          <a
            href='https://www.instagram.com/the.buddies.app'
            target='_blank'
            rel='noopener noreferrer'
            style={{ margin: '10px' }}
          >
            <IconContainer>
              <Icon src='assets/instagram_logo.png' alt='Instagram logo' />
            </IconContainer>
          </a>
        </SocialMediaContainer>
      </FirstRow>
      <SecondRow>
        <StyledH4 color='white'>
          <StyledALink
            href='https://angelapatrinou.com'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              textDecoration: 'none',
              marginRight: 0,
              color: 'white',
            }}
          >
            Made with <StyledSpan>♥</StyledSpan> in Berlin
          </StyledALink>
          {/* by{' '} */}
        </StyledH4>
        {/* <StyledH4>
          <StyledALink
            href='https://angelapatrinou.com'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              textDecoration: 'none',
              marginRight: 0,
            }}
          >
            <HoverH4>Angela Patrinou</HoverH4>
          </StyledALink>
        </StyledH4> */}
      </SecondRow>
      <ThirdRow>
        <Ul>
          {/* <Li>
            <A href='/about' target='_blank' rel='noopener noreferrer'>
              About
            </A>
          </Li> */}
          <Li>
            <A href='/agreement' target='_blank' rel='noopener noreferrer'>
              User Agreement
            </A>
          </Li>
          <Li>
            <A href='/contact' target='_blank' rel='noopener noreferrer'>
              Contact Us
            </A>
          </Li>
          <Li>
            <A href='/policy' target='_blank' rel='noopener noreferrer'>
              Policy
            </A>
          </Li>
        </Ul>
      </ThirdRow>
      <SeparatingLine />
      <FourthRow>
        <FlexSpaced>
          <T.SuperTiny color='white'>
            © 2019 - 2022
          </T.SuperTiny>

          <T.SuperTiny color='white' style={{ maxWidth: '50%' }}>
            Branding | Webdesign:{' '}
            {/* <StyledALink
              href='https://angelapatrinou.com'
              target='_blank'
              rel='noopener noreferrer'
              style={{
                textDecoration: 'none',
                marginRight: 0,
                color: 'white',
              }}
            > */}
            Betty Chatzisavvidou & Angela Patrinou
            {/* </StyledALink>{' '} */}

          </T.SuperTiny>
        </FlexSpaced>
      </FourthRow>
    </FooterContainer>
  )
}
