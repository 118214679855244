import React from 'react'
import './App.css'

import { BrowserRouter, Route } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from './components/style-guide/colors'
import { Home } from './components/Home/Home'
// import Screenshots from './components/Screenshots'
import { About } from './components/more/About'
import { Contact } from './components/more/Contact'
import { Footer } from './components/Footer'
import { Navigation } from './components/Navigation'
import { Policy } from './components/more/Policy'
import { Volunteer } from './components/Volunteer'
import { Stylesheet } from './components/style-guide/Stylesheet'
import { UserAgreement } from './components/more/UserAgreement'
import { WhatsDone } from './components/WhatsDone'
import { Marketing } from './components/Marketing'
import Cofounders from './components/Cofounders'
import Wireframes from './components/more/Wireframes'
import Live from './components/WebApp/Live'

const Container = styled.div`
  // background: ${colors.backgroundColor};
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

function App() {
  return (
    <Container>
      <BrowserRouter>
        <Navigation />
        <Route exact path='/' render={() => <Home />} />
        <Route exact path='/cofounders' render={() => <Cofounders />} />
        <Route exact path='/policy' render={() => <Policy />} />
        <Route exact path='/stylesheet' render={() => <Stylesheet />} />
        <Route exact path='/whatsdone' render={() => <WhatsDone />} />
        <Route exact path='/volunteer' render={() => <Volunteer />} />
        <Route exact path='/marketing' render={() => <Marketing />} />
        <Route exact path='/agreement' render={() => <UserAgreement />} />
        <Route exact path='/wireframes' render={() => <Wireframes />} />
        <Route exact path='/about' render={() => <About />} />
        <Route exact path='/contact' render={() => <Contact />} />
        <Route exact path='/live' render={() => <Live />} />
        <Footer />
      </BrowserRouter>
    </Container>
  )
}

export default App
