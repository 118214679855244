import React from 'react'
import styled from 'styled-components'
import { PageContent } from './WebApp/elements'

export const Logo = styled.div`
  // width: 90vw;
  height: 40vh;
  width: 90vw;
  max-width: 900px;
  // max-width: 1000px;
  background-image: url(logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  transform: rotate(-10deg);
  @media (max-width: 450px) {
    // width: 90%;
    height: 410px;
  }
`

const Ul = styled.ul`
  margin-top: 10px;
`
const Li = styled.li`
  margin-top: 8px;
`

export const Marketing = () => {
  return (
    <PageContent>
      <h1>Monthly Target</h1>
      <p> 12 posts (3 posts / week) for crossposting.</p>
      <p> Style: Informative, cheeky, fun </p>
      <br />
      <h1>Social Media Marketing</h1>
      <Ul>
        <Li>Create and execute a SoMe Marketing Plan</Li>
        <Li>Create SoMe Content (3 posts / week)</Li>
        <Li>Create SoMe Schedulers for Facebook, Instagram, LinkedIn</Li>
      </Ul>
      <br />
      <h1>You</h1>
      <Ul>
        <Li>Are excited to take initiative and learn new things</Li>
        <Li>Are a good communicator, that can take and provide feedback</Li>
        <Li>Organised and can pay attention to detail</Li>
        <Li>Have basic design skills (eg. you know what is a margin)</Li>
        <Li>
          Know Figma (figma.com) or are open to learning it (it is really easy
          and fun to use!)
        </Li>
        <Li>
          Are familiar with using stock photos adding references to the sources
          you use
        </Li>
        <Li>
          Can research for best times to post on facebook/linkedin etc and
          create a short report about it.
        </Li>
        <Li>Bonus if you take pictures / videos to create original content</Li>
        <Li>
          <strong>
            Super Bonus if you've actually lived abroad for a while and
            understand the people we're speaking to.
          </strong>
        </Li>
        <Li>Have ideas</Li>
      </Ul>
      <br />
      <h2>Social Media </h2>
      <Ul>
        <Li>
          <a
            href='https://www.facebook.com/theBuddiesApp'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </a>
        </Li>
        <Li>
          <a
            href='https://www.linkedin.com/company/buddies'
            target='_blank'
            rel='noopener noreferrer'
          >
            LinkedIn
          </a>
        </Li>
        <Li>
          <a
            href='https://www.instagram.com/the.buddies.app/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Instagram
          </a>
        </Li>

        <Li>
          <a
            href='https://youtu.be/mNTF_XFMWC4'
            target='_blank'
            rel='noopener noreferrer'
          >
            Promotional Video
          </a>
        </Li>
      </Ul>
      <br />
      <h2>Next steps </h2>
      <p>
        If you're interested checkout the <a href='/whatsdone'>resources</a>,
        create one example post and send it to the.buddies.app@gmail.com along
        with your CV, a paragraph on why you want to get involved in this
        project, and a list the design tools you can use as well as other
        marketing/SoMe related tools you are familiar with.
      </p>
    </PageContent>
  )
}
