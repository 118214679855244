import React, { useState, useEffect } from 'react'
import firebase from 'firebase'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import {
  firebaseConfigDevelopment,
  firebaseConfigProduction,
} from '../../utils/firebaseConfig'
import { testUsers } from './testUsers'
import * as T from '../style-guide/typography'
import { User } from './User'

export const Container = styled.div`
  // background: orange;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    height: auto;
    overflow: hidden;
  }
`
export const Button = styled.button`
  height: 60px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: inherit;
  background-color: rgb(40, 183, 193);
  border-radius: 50px;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin-right: 5px;
  &: hover {
    cursor: pointer;
    background-color: #e91e63;
  }
`
try {
  firebase.initializeApp(
    process.env.NODE_ENV !== 'development'
      ? firebaseConfigDevelopment
      : firebaseConfigProduction
  )
} catch (err) {
  console.error('Firebase initialization error', err.stack)
}

const UserListContainer = styled.div`
  background: white;
  width: 700px;
  height: 800px;
  border-radius: 30px;
  margin-top: 50px;
  overflow: scroll;
`

function ContactBox() {
  // const [email, setEmail] = useState('testing@testing.testing')
  // const [password, setPassword] = useState('testing')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggedUser, setLoggedUser] = useState(null) // it gets month from time minutes for some reason :O
  const [userKeys, setUserKeys] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [usersToDisplay, setUsersToDisplay] = useState(testUsers)
  const [error, setError] = useState('')

  console.log('loggedUser', loggedUser)

  useEffect(() => {
    setAllUsers(testUsers)
    setUserKeys(Object.keys(testUsers))
  }, [])

  return (
    <Container>
      <T.H2>
        <br />
        <FormControl
          style={{ width: '70%' }}
          onChange={() => {}}
          onSubmit={() => {
            console.log('submitted.')
          }}
        >
          <TextField
            id='email'
            type='email'
            label='email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
          <TextField
            id='password'
            label='password'
            type='password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            autoCapitalize='none'
          />
          <br />
          <div>
            {error ? (
              <div style={{ color: 'red' }}>{error}</div>
            ) : (
              <Button
                onClick={() => {
                  // setError(false)
                  //validate email format
                  // createAccount()
                  if (
                    email === 'mrsblackgr@gmail.com' ||
                    email === 'testing@testing.testing'
                  ) {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, password)
                      .then((data) => {
                        setLoggedUser(data.user)
                        // console.log('logged!', data)
                        firebase
                          .database()
                          .ref('/users')
                          // .orderByChild('uid/order')
                          .once('value')
                          .then((data) => {
                            const retreivedUsers = data.val()
                            // console.log('Got them!', retreivedUsers)
                            let usersArray = []
                            setUserKeys(Object.keys(retreivedUsers))
                            console.log('retreivedUsers', retreivedUsers)
                            Object.keys(retreivedUsers).forEach((user) =>
                              usersArray.push(retreivedUsers[user])
                            )
                            setAllUsers(usersArray)
                            setUsersToDisplay(usersArray)
                          })
                      })
                  } else {
                    setError('🗝 Only admins can access this page!')
                  }
                }}
              >
                <T.ButtonText>Login</T.ButtonText>
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <Button
              onClick={() => {
                const tempAll = allUsers.filter((user) => {
                  return user.profileHasBeenApproved === true
                })
                setUsersToDisplay(tempAll)
              }}
            >
              <T.ButtonText>Approved</T.ButtonText>
            </Button>
            <Button
              onClick={() => {
                const tempAll = allUsers.filter((user) => {
                  return user.profileHasBeenApproved === false
                })
                setUsersToDisplay(tempAll)
              }}
            >
              <T.ButtonText>Declined</T.ButtonText>
            </Button>
            <Button
              onClick={() => {
                setUsersToDisplay(allUsers)
              }}
            >
              <T.ButtonText>All</T.ButtonText>
            </Button>
          </div>
        </FormControl>
        <UserListContainer>
          <br />
          User List {userKeys.length > 1 && <span>({userKeys.length})</span>}
          {usersToDisplay.length > 1 &&
            usersToDisplay.map((user) => {
              if (user) {
                return <User user={user} />
              } else return null
            })}
        </UserListContainer>
        <br />
        <br />
        <br />
        And now...
      </T.H2>{' '}
    </Container>
  )
}

export default ContactBox

//upload picture: https://dev.to/itnext/how-to-do-image-upload-with-firebase-in-react-cpj (refactored version)
