import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import { colors } from './style-guide/colors'
import styled from 'styled-components'
// import About from './About'

const NavigationContainer = styled.div`
  width: 100%;
  height: 100px;
  background: white;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  margin: 0 60px;
  max-width: 1200px;
  @media (max-width: 500px) {
    height: 70px;
  }
`

export const NavLogo = styled(Link)`
  width: 170px;
  background-image: url(logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 500px) {
    width: 150px;
  }
`

const NaviBarContainer = styled.div`
  margin: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    justify-content: center;
  }
`
const NavItemsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    /* display: none; */
  }
`
const MobileNavItemsContainer = styled.div`
  display: none;
`
const NavItem = styled(Link)`
  height: 100%;
  padding: 0px 13px;
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.333;
  color: rgb(15 7 23);
  display: flex;
  align-items: center;
  @media (max-width: 650px) {
    padding: 0;
  }
  &: hover {
    cursor: pointer;
    color: ${colors.pink};
  }
`

const CreateAccountLink = styled(ScrollLink)`
  background: linear-gradient(to right, #f9a356, #fc6382);
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 800;
  color: white;
  margin-left: 10px;
  &: hover {
    cursor: pointer;
    background: ${colors.pink};
  }
  @media (max-width: 500px) {
    display: none;
  }
`

const MenuIcon = styled.img`
  width: 40px;
`

export const Navigation = () => {
  const [showMobileMenuModal, setShowMobileMenuModal] = useState(null)
  return (
    <div
      style={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <NavigationContainer>
        <NaviBarContainer>
          <NavLogo to='/' />
          <NavItemsContainer>
            {/* <NavItem to='/whatsDone'>What's Done</NavItem> */}
            {/* <NavItem to='/about'>About</NavItem> */}
            <CreateAccountLink
              to={'createAccount'}
              smooth={true}
              duration={1500}
              offset={-110}
              className='no-underline'
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              Create Account
            </CreateAccountLink>
          </NavItemsContainer>
          <MobileNavItemsContainer
            onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
          >
            <NavItem
              to={showMobileMenuModal ? '/' : '/about'}
              // onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
            >
              <MenuIcon src='assets/icons/menu.png' />{' '}
            </NavItem>
          </MobileNavItemsContainer>
          {/* {showMobileMenuModal && (
          <MobileMenu>
            123
            <NavLogo
              to='/'
              onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
            />
            <NavItem
              to='/about'
              onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
            >
              About
            </NavItem>
            <NavItem
              to='/whatsDone'
              onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
            >
              What's Done
            </NavItem>
            <CloseModalButton
              src='assets/icons/x.png'
              onClick={() => setShowMobileMenuModal(!showMobileMenuModal)}
            ></CloseModalButton>
          </MobileMenu>
        )} */}
        </NaviBarContainer>
      </NavigationContainer>
    </div>
  )
}
