export const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyATo0Iqyy_ynTauz3H1AjSlxf-JkXjGRDQ',
  authDomain: 'buddies-app-development-us.firebaseapp.com',
  databaseURL: 'https://buddies-app-development-us-default-rtdb.firebaseio.com',
  projectId: 'buddies-app-development-us',
  storageBucket: 'buddies-app-development-us.appspot.com',
  messagingSenderId: '574616955028',
  appId: '1:574616955028:web:c72274c642cf2686b66c81',
  measurementId: 'G-V225S8PMHG',
}

export const firebaseConfigProduction = {
  apiKey: 'AIzaSyDhxl50rR-W3XbbqpCrl0LFho5HT2crwZo',
  authDomain: 'buddies-production-e6f71.firebaseapp.com',
  databaseURL: 'https://buddies-production-e6f71-default-rtdb.firebaseio.com',
  projectId: 'buddies-production-e6f71',
  storageBucket: 'buddies-production-e6f71.appspot.com',
  messagingSenderId: '399106340834',
  appId: '1:399106340834:web:caf3515537058b65896bbb',
  measurementId: 'G-51HW0BLJB0',
}