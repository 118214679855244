import React from 'react'
import { colors } from './style-guide/colors'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 1200px;
  margin: 50px;
  color: ${colors.charcoal};
  @media (max-width: 450px) {
    margin: 30px;
    margin-top: 70px;
  }
`

export const Logo = styled.div`
  // width: 90vw;
  height: 40vh;
  width: 90vw;
  max-width: 900px;
  // max-width: 1000px;
  background-image: url(logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  transform: rotate(-10deg);
  @media (max-width: 450px) {
    // width: 90%;
    height: 410px;
  }
`

const Ul = styled.ul`
  margin-top: 10px;
`
const Li = styled.li`
  margin-top: 8px;
`

export const WhatsDone = () => {
  return (
    <Content>
      <h1>What's Done?</h1>
      <h2>Analysis</h2>
      <Ul>
        <Li>
          Business Plan{' '}
          <a
            href='https://www.dropbox.com/s/s5tdk2puqayugtu/Buddies-Business_Plan_13.03.2022-market-brief.pdf?dl=0'
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </a>
        </Li>
      </Ul>
      <h2>Design </h2>
      <Ul>
        <Li>
          Checkout the Designs{' '}
          <a
            href='https://www.figma.com/file/KWr9if0cm5F8LMhYiGUDiE/*Buddies-Marketing-%26-Design-System'
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </a>
          .
        </Li>
      </Ul>
      <h2>Social Media ideas </h2>
      <Ul>
        <Li>
          Checkout some ideas{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://docs.google.com/spreadsheets/d/1mXERzOdR0vS-fX0GDENc0H6BQQc07DR6B-ZBJx8iuhM/edit?copiedFromTrash#gid=0'
          >
            link
          </a>
          .
        </Li>
      </Ul>
      <h2>Development</h2>
      <Ul>
        <Li>Done!</Li>
        <Li>New features are in progress</Li>
      </Ul>

      <h2>Getting the word out </h2>
      <Ul style={{ marginBottom: '100px' }}>
        <Li>
          Social Media:
          <ul>
            <Li>
              <a
                href='https://www.facebook.com/theBuddiesApp'
                target='_blank'
                rel='noopener noreferrer'
              >
                Facebook
              </a>
            </Li>
            <Li>
              <a
                href='https://www.linkedin.com/company/buddies'
                target='_blank'
                rel='noopener noreferrer'
              >
                LinkedIn
              </a>
            </Li>
            <Li>
              <a
                href='https://www.instagram.com/the.buddies.app/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Instagram
              </a>
            </Li>

            <Li>
              <a
                href='https://youtu.be/mNTF_XFMWC4'
                target='_blank'
                rel='noopener noreferrer'
              >
                Promotional Video
              </a>
            </Li>
          </ul>
        </Li>
        {/* <Li>
          Participated in "Make it Lean" Startup competition by Startup
          Incubator Berlin{' '}
          <a
            href='https://www.startup-incubator.berlin/make-it-lean-contest-2020/buddies/'
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </a>
        </Li>
        <Li>
          Mentioned by International Hellenic University{' '}
          <a
            href='https://www.linkedin.com/posts/international-hellenic-university_ihuabrmscabrinabrmanagement-ihuproudofitsstudents-activity-6757985373009317888-wtr7/'
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </a>
        </Li> */}
      </Ul>
    </Content>
  )
}
