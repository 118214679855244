export const colors = {
  cetaceanBlue: '#001134',
  sapphire: '#002080',
  imperialBlue: '#002699',
  strongBlue: '#002DB3',
  trueBlue: '#005CDB',
  navyBlue: '#007FE8',
  pacificBlue: '#009DDD',

  black: '#000000',
  charcoal: '#333333',
  mediumGray: '#8C8C8C',
  lightGray: '#CDCDCD',
  whisper: '#E6E6E6',
  pearl: '#F2F2F2',
  snow: '#FBFBFB',
  lightSnow: '#FCFCFC',

  white: '#FFFFFF',
  darkMagenta: '#B1009D',
  iris: '#00B8C3',
  carribeanGreen: '#00D0A3',
  riceFlower: '#D6F0F3',
  pink: '#E9677D',
  yellow: '#fecc2e',
  backgroundColor: `linear-gradient(
    // #f9d1d7,
    // #fdeedb,
    // #dfeccb,
    #acd6ed,
    // #a7e1df,
    #dfeccb,
    #fee8aa,
    #fbd5a6,
    #f6bac3,
    #d5baed
  )`,
}
