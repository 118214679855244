import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import { colors } from './style-guide/colors'
import * as typography from './style-guide/typography'

export const Block = styled.div`
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  width: 90%;
  border-radius: 40px;
`
export const LogoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 50px;
  margin-top: 80px;
  @media (max-width: 450px) {
    margin: 0;
  }
`

export const Logo = styled.div`
  height: 40vh;
  width: 90vw;
  max-width: 900px;
  background-image: url(logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  transform: rotate(-10deg);
  @media (max-width: 450px) {
    width: 95vw;
  }
`
export const WhatWouldYouLikeToDo = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url('WhatWouldYouLikeToDo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
// export const Logo = styled.div`
//   width: 90vw;
//   height: 90vh;
//   background-image: url('logo-2.png');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   @media (max-width: 450px) {
//     // width: 90%;
//     // min-height: 110px;
//   }
// `
export const CreateAccountButton = styled.button`
  height: 60px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: 1.5em;
  margin-bottom: 10px;
  text-transform: inherit;
  background-color: rgb(40, 183, 193);
  border-radius: 50px;
  color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transform: rotate(-8deg);
  &: hover {
    cursor: pointer;
    background-color: #e91e63;
  }
`

export const ProfilePicture = styled.label`
  font-family: 'Open Sans', sans-serif;
  margin-top: 20px;
  // margin-bottom: 15px;
  font-size: 18px;
  background: #97bd59;
  width: 90px;
  height: 60px;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
  &: hover {
    cursor: pointer;
    // background: #e91e63a1;
    background: #fecc2e;
  }
`
export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
//stopped using this:
export const SectionContainer = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 10px;
  overflow: scroll;
  font-size: 18px;
  max-width: 600px;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  @media (max-width: 750px) {
    width: 85%;
    font-size: 16px;
  }
`
export const StyledALink = styled.a`
  color: ${typography.fontColor};
  // text-decoration: none;
  display: inline;
  margin-bottom: 20px;
  &: hover {
    cursor: pointer;
    color: #e91e63;
  }
`
export const ScrollFullContainer = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  font-size: 18px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

export const Section = styled.div`
  width: 100%;
  height: 33.33%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  @media (max-width: 750px) {
    height: auto;
    min-height: 200px;
    font-size: 16px;
  }
`

export const AboutSection = styled.div`
  display: flex;
  width: 100%;
  // background: pink;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 270px;
  flex-shrink: 0;
  @media (max-width: 450px) {
    min-height: 150px;
    height: auto;
    margin: 10px 0;
  }
`
export const GetInvolved = styled.div`
  width: 400px;
  height: 33.33%;
  display: flex;
  flex-direction: column;
  // background: orange;
  @media (max-width: 500px) {
    width: 100%;
    min-height: 200px;
    font-size: 16px;
  }
`

export const Description = styled(Section)`
  align-items: center;
  text-align: start;
  max-width: 500px;
  line-height: 1.6;
  font-weight: 500;
  min-height: 270px;
  @media (max-width: 750px) {
    min-height: 200px;
  }
`
export const TextContainer = styled(Description)`
  height: auto;
  min-height: auto;
`

export const Error = styled.div`
  color: #d80e53;
  width: 230px;
  position: absolute;
  top: 100px;
  font-weight: 600;
  background: white;
  z-index: 100;
  padding: 10px;
  border-radius: 30px;
  line-height: 1.6;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`
export const Success = styled.div`
  position: absolute;
  background: white;
  padding: 20px 30px;
  z-index: 100;
  top: -80px;
  height: 90px;
  color: rgb(40, 183, 193);
  font-weight: 600;
  width: 217px;
  line-height: 25px;
  font-size: 1.2em;
`

export const NavigationButtonItem = styled(Link)`
  display: flex;
  justify-content: center;
  width: 110px;
  color: black;
  padding: 10px;
  padding-bottom: 5px;
  margin: 0 15px;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.5px;
  @media (max-width: 750px) {
    height: 20px;
    width: 72px;
    margin: 0 5px;
    font-size: 13px;
    flex-shrink: 0;
  }
`
export const ButtonItem = styled.div`
  display: flex;
  justify-content: center;
  width: 110px;
  color: black;
  padding: 10px;
  padding-bottom: 5px;
  margin: 0 15px;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.5px;
  @media (max-width: 750px) {
    height: 20px;
    width: 72px;
    margin: 0 5px;
    font-size: 13px;
    flex-shrink: 0;
  }
`
export const AvailableOn = styled.div`
  width: 80vw;
  height: 100px;
  background-image: url('assets/GooglePlay.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

// CONTACT BOX

export const JoinWaitList = styled.div`
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const IPhoneContainer = styled.div`
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 280px;
  height: 540px;
  background: white;
  // border: 10px solid orange;
  box-shadow: 10px 10px 0 #823e8e8f;
  border-radius: 45px;
  @media (max-width: 450px) {
    border: 2px solid orange;
    box-shadow: 3px 3px 0 #ad7127;
  }
`

export const IPhoneOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // width: 70%;
  height: 100%;
  // background: rgba(255, 1, 1, 0.4);
`

export const HiddenInput = styled.input`
  // width: 0.1px;
  // height: 0.1px;
  // opacity: 0;
  // overflow: hidden;
  // position: absolute;
  // z-index: -1;
`

export const BirthdayLabel = styled.label`
  color: #757575;
  margin: 10px 0;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
`
export const BirthdayInput = styled.input`
  border: none;
  border-bottom: 1px solid #949494;
  color: rgb(117 117 117);
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`
