import React from 'react'
import * as typography from '../style-guide/typography'
import styled from 'styled-components'
import { Home } from '../Home/Home'

const WireframesContainer = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-text: center;
`
function Wireframes({ children }) {
  return (
    <Home
      children={
        <WireframesContainer>
          <typography.H1>
            <a
              href='https://www.figma.com/proto/4XIEus2RUF2POh4kRaEqJa/Buddies---Design?node-id=371%3A212&scaling=min-zoom'
              target='_blank'
              rel='noopener noreferrer'
            >
              Link
            </a>{' '}
            to the Design Prototype.
          </typography.H1>
        </WireframesContainer>
      }
    />
  )
}

export default Wireframes
