import styled from 'styled-components'
import { colors } from './colors'

// const textGray = '#505964'
const textGray = '#2f2f3f'
// export const fontColor = colors.charcoal
export const fontColor = textGray
// export const fontColor = 'rgb(15 7 23)'
// export const fontColor = 'white'

// export const shadow = '2px 2px 2px rgb(0 0 0 / 20%)'
const fontFamily = `'Montserrat', 'Open Sans', sans-serif`
export const shadow = 'none'

export const Title = styled.h1`
  font-family: 'Montserrat', 'Manrope', 'Helvetica Neue', Roboto, 'Segoe WP',
    'Segoe UI';
  font-size: sem;
  line-height: 2.5em;
  color: ${({ color }) => color || fontColor};
  text-shadow: ${shadow};
  /* color: pink; */
`
export const H1 = styled.h1`
  font-size: 3em;
  font-family: 'Montserrat', 'Manrope', 'Helvetica Neue', Roboto, 'Segoe WP',
    'Segoe UI', sans-serif;
  color: ${({ color }) => color || fontColor};
  font-style: normal;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 630px;
  text-shadow: ${shadow};
  font-weight: 500;
`
export const H2 = styled.h2`
  font-family: ${fontFamily};
  color: ${({ color }) => color || fontColor};
  text-shadow: ${shadow};
  max-width: 800px;
  font-weight: 400;
  line-height: 1.5em;
  text-align: center;
  max-width: 630px;
  @media (max-width: 500px) {
    padding: 0 30px;
    font-size: 1.2em;
  }
`
export const H3 = styled.h3`
  font-family: ${fontFamily};
  // font-weight: 600;
  // font-size: 1.2em;
  line-height: 1.7;
  color: ${({ color }) => color || fontColor};
  text-align: center;
  max-width: 630px;
  margin: 20px;
  font-weight: 600;
  text-shadow: ${shadow};
`
export const H4 = styled.h4`
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  // font-size: 1.2em;
  text-align: center;
  padding: 0 20px;
  // margin: 20px;
  color: ${({ color }) => color || fontColor};
  text-shadow: ${shadow};
`
export const Regular = styled.div`
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  max-width: 630px;
  line-height: 1.6em;
  margin-bottom: 10px;
  color: ${({ color }) => color || fontColor};
  text-shadow: ${shadow};
  @media (max-width: 1000px) {
    padding: 0 30px;
  }
`

export const RegularSpan = styled.span`
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  max-width: 630px;
  line-height: 1.6em;
  margin-bottom: 10px;
  color: ${({ color }) => color || fontColor};
  text-shadow: ${shadow};
  @media (max-width: 1000px) {
    padding: 0 30px;
  }
`
export const Bold = styled(RegularSpan)`
  font-size: 34px;
  max-width: 900px;
  /* color: #2f2f3f; */
  font-weight: 800;
  text-align: center;
`
export const Highlighted = styled(Bold)`
  color: #fd6b64;
  padding: 0;
`

export const Small = styled(Regular)`
  font-size: 18px;
  /* line-height: 20px; */
`
export const Tiny = styled(Regular)`
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding: 0;
`
export const SuperTiny = styled(Regular)`
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0;
  @media (max-width: 50px) {
    margin-top: 10px;
    background: green;
  }
`

export const RegularWithMargin = styled(Regular)`
  @media (max-width: 1000px) {
    padding: 0 30px;
  }
`
export const ExplanatoryText = styled.div``

export const Prompt = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: ${fontColor};
  margin: 30px 10px;
  max-width: 630px;
  @media (max-width: 450px) {
    font-size: 16px;
    margin: 10px 0;
  }
`
export const ButtonText = styled(Prompt)`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  max-width: 630px;
  color: ${({ color }) => color || 'white'};
  @media (max-width: 450px) {
    font-size: 16px;
    margin: 10px 0;
  }
`
export const Subtitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ color }) => color || colors.mediumGray};
`
