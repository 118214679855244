import React from 'react'
import '../App.css'
import * as typography from './style-guide/typography'
import styled from 'styled-components'

const Container = styled.div`
  // margin-top: 70px;
  padding: 30px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

function Cofounders() {
  return (
    <Container>
      <typography.H2 color={'carcoal'}>Cofounders</typography.H2>
      <p>
        Previous experience with a similar solution is desirable but not
        necassary.
      </p>
      <br />
      <typography.H3>1. Creative</typography.H3>
      <p>In charge of the whole App aesthetics and design:</p>
      <ul>
        <li>App Branding</li>
        <li>UI/UX Design</li>
        <li>Logo Design</li>
        <li>Design System</li>
      </ul>
      <br />
      <typography.H3>2. Engineering / Fullstack developer</typography.H3>
      <p>Come up with the best architecture for the app:</p>
      <ul>
        <li>
          Use state of the art technologies to set up the frontend and backend
        </li>
        <li>
          Any technology can be used as long as it is justified. Example
          technologies would be Node, React Native, MongoDB
        </li>
        <li>Build a team from scratch</li>
      </ul>
      <br />
      <hr />
      <typography.H3>Get in touch on </typography.H3>
      the.buddies.app@gmail.com
      <br />
      <br />
      <br />
    </Container>
  )
}

export default Cofounders
