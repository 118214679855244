import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 40px;
  @media (max-width: 500px) {
    margin-bottom: 50px;
    max-width: 90%;
    overflow: hidden;
  }
`
const VideoContainer = styled.div`
  overflow: hidden;
  background: black;
  // border: 10px solid orange;
  box-shadow: 10px 10px 0 #ad7127;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 900px) {
    width: 105%;
    background: black;
  }
  @media (max-width: 500px) {
    height: 350px;
    border-radius: 0;
  }
`

const Section = styled.div`
  width: 90vw;
  min-height: 500px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 70px 0;
  @media (max-width: 450px) {
    margin: 30px 0;
    height: 400px;
    min-height: auto;
  }
`
const VideoContainerDesktop = styled(Section)`
  display: contents;
  margin-bottom: 50px;
  height: 90vh;
  overflow: hidden;
  @media (max-width: 670px) {
    display: none;
  }
`
const VideoContainerMobile = styled(Section)`
  display: none;
  @media (max-width: 670px) {
    display: contents;
  }
`
function About() {
  return (
    <Container>
      {/* <typography.H1>Watch the video! 🤩</typography.H1> */}
      <VideoContainer>
        <VideoContainerDesktop>
          <iframe
            title='desktop video'
            src='https://www.youtube.com/embed/mNTF_XFMWC4'
            width='640'
            height='480'
            frameborder='0'
            allow='autoplay; fullscreen'
            allowfullscreen
          ></iframe>
        </VideoContainerDesktop>
        <VideoContainerMobile>
          <iframe
            title='mobile video'
            src='https://www.youtube.com/embed/mNTF_XFMWC4'
            width='300'
            height='350'
            frameborder='0'
            allow='autoplay; fullscreen'
            allowfullscreen
          ></iframe>
        </VideoContainerMobile>
      </VideoContainer>
    </Container>
  )
}

export default About
