import React from 'react'
import styled from 'styled-components'
import * as T from '../style-guide/typography'
import { PageFold } from './PageFold'

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 200px;
  }
`
export const AvailableOnGoogle = styled.div`
  max-width: 300px;
  width: 250px;
  height: 80px;
  margin: 20px;
  background-image: url('assets/GooglePlay-badge.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 500px) {
    width: 200px;
    margin: 0px;
  }
`
export const AvailableOnApple = styled(AvailableOnGoogle)`
  background-image: url('assets/AppStore-badge.png');
`
export const MotoBanner = styled(T.H2)`
  margin-top: 50px;
  word-break: break-word;
  font-size: 30px;
  text-shadow: none;
`

const ContainingBlock = styled.div`
  /* background: yellow; */
  margin: 20px;

  /* width: 90%; */
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`
const MiddleContainingBlock = styled(ContainingBlock)`
  flex-direction: row-reverse;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const Block = styled.div`
  /* background: yellow; */
  width: 400px;
  height: 400px;
  margin: 20px;
  @media (max-width: 500px) {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 300px;
    width: 100%;
  }
`
const Image = styled(Block)`
  /* background: orange; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 15%;
`
const Image1 = styled(Image)`
  background-image: url('assets/image1.jpg');
`

const HeaderImageOverlay = styled(Block)`
  margin: 0;
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 100;
  background-image: url('assets/pictureOverlay.png');
  background-position: center center;
  background-size: cover;
  left: 20px;
  bottom: 20px;
  opacity: 0.75;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 310px;
    width: 100%;
    left: 0;
    top: 0;
  }
`

const HeaderImageOverlay2 = styled(Block)`
  margin: 0;
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 100;
  background-image: url('assets/pictureOverlay.png');
  background-position: center center;
  background-size: cover;
  right: 20px;
  bottom: 20px;
  opacity: 0.75;
  @media (max-width: 500px) {
    flex-direction: column;
    /* top: 70px; */
    height: 310px;
    width: 100%;
    right: 0;
    top: 0;
  }
`

const ValakiContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`
const VelakiIcon = styled.div`
  height: 30px;
  width: 30px;
  background-image: url('./assets/velaki.png');
  background-size: contain;
  margin-right: 10px;
  flex-shrink: 0;
`
const Velaki = ({ text = 'text text' }) => (
  <ValakiContainer>
    <VelakiIcon />
    <div>{text}</div>
  </ValakiContainer>
)
const Image2 = styled(Image)`
  background-position: center center;
  background-image: url('assets/image2.jpg');
`
const Image3 = styled(Image)`
  background-position: center center;
  background-image: url('assets/girls_drinking.png');
`
const Logo = styled(Image)`
  background-image: url('logo.png');
  width: 80%;
  max-width: 500px;
  background-size: contain;
  background-position: center center;
  max-height: 200px;
  @media (max-width: 500px) {
    max-height: 200px;
  }
`

const TextContainer = styled(Block)`
  /* background: red; */
  font-family: 'Montserrat';
  font-size: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    margin: 20px;
    max-width: 350px;
    height: auto;
    text-align: center;
  }
`

export const Home = () => {
  return (
    <>
      <PageFold />
      <ContainingBlock>
        <T.Bold>
          <T.Highlighted>Buddies</T.Highlighted> is a{' '}
          <T.Highlighted>fun community</T.Highlighted> where you can make new
          friends <T.Highlighted>over what matters</T.Highlighted>!
        </T.Bold>
      </ContainingBlock>
      <ContainingBlock>
        <HeaderImageOverlay />
        <Image1 />
        <TextContainer>
          <div>
            <T.Regular>
              Today moving between cities and countries is easier than ever
              before!
            </T.Regular>
            <br />
            <T.Small>Many people arrive in big cities every day.</T.Small>
            <br />
            <T.Small>
              Busy schedules are the norm and meeting new people can be tricky!
            </T.Small>
          </div>
        </TextContainer>
      </ContainingBlock>
      <MiddleContainingBlock>
        <HeaderImageOverlay2 />
        <Image2 />
        <TextContainer>
          <div>
            <T.Regular>So how do we meet new people?</T.Regular>
            <br />
            <T.Small>
              <Velaki text={'Through work'} />
            </T.Small>

            <br />
            <T.Small>
              <Velaki
                text={`Groups with activities like martial arts, dancing or gym. On
							MeetUp people can also join a group. But what if the activity they
							are interested in is just for two people, for example tennis,
							table tennis or playing chess?`}
              />
            </T.Small>

            <br />
            <T.Small>
              <Velaki text={'Over dating apps '} />
            </T.Small>
          </div>
        </TextContainer>
      </MiddleContainingBlock>
      <ContainingBlock>
        <HeaderImageOverlay />
        <Image3 />
        <TextContainer>
          <T.Small>
            After making these observations, we decided there should be{' '}
            <strong>a better way to meet</strong>. So we built it!
          </T.Small>
        </TextContainer>
      </ContainingBlock>
      <Logo />
      <T.Title>Join now!</T.Title>
      <ContainingBlock>
        <LinksContainer>
          <a
            href='https://play.google.com/store/apps/details?id=com.patrinoua.buddies'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AvailableOnGoogle />
          </a>
          <a
            href='https://apps.apple.com/de/app/buddies-app/id1559816910#?platform=iphone'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AvailableOnApple />
          </a>
        </LinksContainer>
      </ContainingBlock>
      <ContainingBlock />
    </>
  )
}
