import React from 'react'
import * as T from './typography'

export const Stylesheet = () => {
  return (
    <div style={{ margin: '50px', maxWidth: '700px' }}>
      Stylesheet
      <T.H1>I'm H1</T.H1>
      <T.H2>I'm H2</T.H2>
      <T.H3>I'm H3</T.H3>
      <T.H4>I'm H4</T.H4>
      <T.Title>I'm Title</T.Title>
      <T.Regular>I'm Regular</T.Regular>
      <T.Small>I'm Small</T.Small>
      <T.Subtitle>I'm Subtitle</T.Subtitle>
      <T.ButtonText color='black'>I'm ButtonText</T.ButtonText>
    </div>
  )
}
