import React from 'react'
import * as typography from '../style-guide/typography'
import styled from 'styled-components'
import Video from './Video'

const ColorfulContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 50px;
`

export const Contact = ({ children }) => {
  return (
    <ColorfulContainer>
      <typography.H2 style={{ marginTop: 50 }}>Contact</typography.H2>
      <typography.Regular>You can contact us on</typography.Regular>
      <typography.H3>the.buddies.app@gmail.com</typography.H3>
      <Video />
      <br />
      <br />
    </ColorfulContainer>
  )
}
