import React from 'react'
import styled from 'styled-components'
import * as T from '../style-guide/typography'

const PageFoldContainer = styled.div`
  height: 60vh;
  width: 100vw;
  flex-shrink: 0;
  max-height: 1000px;
  margin-bottom: 50px;
  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`
const Overlay = styled(PageFoldContainer)`
  position: absolute;
  top: 100px;
  z-index: 10;
  background-image: url('assets/overlay.png');
  background-position: center center;
  background-size: cover;
  /* opacity: 0.7; */
  @media (max-width: 500px) {
    flex-direction: column;
    top: 70px;
  }
`
const HeaderImage = styled(PageFoldContainer)`
  z-index: 20;
  height: 100%;
  width: 100%;
  background-image: url(assets/background-small.png);
  background-size: cover;
  background-position: center;
`
const PageFoldContent = styled(PageFoldContainer)`
  position: absolute;
  top: 100px;
  z-index: 50;
  /* background: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    top: 70px;
  }
`
const Lala = styled.div`
  /* background: pink; */
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    /* height: 200px; */
  }
`
export const AvailableOnGoogle = styled.div`
  max-width: 300px;
  width: 250px;
  height: 80px;
  margin: 20px;
  background-image: url('assets/GooglePlay-badge.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 500px) {
    width: 200px;
    margin: 0px;
  }
`
export const AvailableOnApple = styled(AvailableOnGoogle)`
  background-image: url('assets/AppStore-badge.png');
`
export const MotoBanner = styled(T.H2)`
  margin-top: 50px;
  word-break: break-word;
  font-size: 30px;
  text-shadow: none;
`
export const PageFold = () => {
  return (
    <>
      <Overlay />
      <PageFoldContainer>
        <HeaderImage />
        <PageFoldContent>
          <Lala>
            <T.H1 color='white'>Join the community!</T.H1>
          </Lala>
          <Lala>
            <LinksContainer>
              <a
                href='https://play.google.com/store/apps/details?id=com.patrinoua.buddies'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AvailableOnGoogle />
              </a>
              <a
                href='https://apps.apple.com/de/app/buddies-app/id1559816910#?platform=iphone'
                target='_blank'
                rel='noopener noreferrer'
              >
                <AvailableOnApple />
              </a>
            </LinksContainer>
          </Lala>
        </PageFoldContent>
      </PageFoldContainer>
    </>
  )
}
