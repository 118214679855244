import React from 'react'
import * as T from '../style-guide/typography'
import { PageContent } from '../WebApp/elements'

export const Policy = () => {
  return (
    <PageContent>
      <T.H1>Privacy Policy</T.H1>
      Welcome to the Buddies Privacy Policy ("Policy")! This explains how we
      collect, store, protect, and share your information, and with whom we
      share it. If you have any questions or comments about this Policy, please
      feel free to contact us at the.buddies.app@gmail.com.
      <T.H2>1. COLLECTION OF INFORMATION</T.H2>
      <T.H4>Registration Information</T.H4>
      When you download the App and create an account ("Account"), we may
      collect certain information ("Registration Information") about you, such
      as:
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Cell phone number</li>
        <li>Date of birth</li>
        <li>Photographs</li>
        <li>Location</li>
      </ul>
      <p>
        Login information and friend lists for social media accounts that you
        connect to your Buddies Account (this could include, for example, your
        Facebook and Instagram accounts ("Social Media Accounts"). You will also
        be required to create a password in connection with the registration of
        your Account.
      </p>
      <p>
        Once you register, you will be able to review and change this
        information at any time by accessing your “Profile” page! You control
        your user profile and are able to correct or update (other than your
        email address, cell phone number, date of birth, gender and location
        (which is automatically updated)) your information at any time by just
        logging in to Buddies.
      </p>
      <p>
        The information we collect helps to enhance the App and verify our
        Users. Registration Information such as your name and username may be
        visible to the public on your profile page.
      </p>
      <T.H4>Additional Information</T.H4>
      <p>
        We may ask you to provide your full name, address and email address.
        Such details may be shared with third parties for purposes relating to,
        for example, any of our loyalty programs, sending gifts or delivery of
        merchandise.
      </p>
      <p>
        We may also collect additional information in connection with your use
        of Buddies, for example if you use our messaging function.
      </p>
      <p>
        If you contact our Customer Support team, we will receive your email
        address, and may track your IP address as well as the information you
        send to us to help resolve your query. We will keep records of our
        communications with you, including any complaints that we receive from
        you about other Users (and from other Users about you).
      </p>
      <p>
        We recommend and encourage you (and all our members) to think carefully
        about the information you disclose about yourself. We also do not
        recommend that you put email addresses, URLs, instant messaging details,
        phone numbers, full names or addresses, credit card details, national
        identity numbers, drivers' license details and other sensitive
        information on your profile which is open to abuse and misuse.
      </p>
      <p>
        Please be careful about posting sensitive details about yourself on your
        profile such as your religious denomination and health details. You may
        also choose to add sensitive information about yourself when you add
        certain Buddies Badges to your profile, such as your religion and
        political leanings. While you may voluntarily provide this information
        to us when you create your profile, there is no requirement to do so.
        Please remember that photographs that you post on Buddies may reveal
        information about yourself as well. Where you do upload and choose to
        tell us sensitive information about yourself, including through adding
        certain Badges to your profile, you are explicitly consenting to our
        processing of your information and making this public to other users.
      </p>
      <p>
        When you post information about yourself or use the messaging function,
        the amount of personal information you share is at your own risk. Please
        see Section 4 below for more information on who can access what you post
        on Buddies. If you post anything that is inconsistent with our Terms and
        Conditions of Use, we reserve the right to terminate your Account - we
        really don't want to have to do this so please play by the rules!
      </p>
      <p>
        For safety and security and to ensure you have the best possible user
        experience, we might ask users to verify their accounts and might ask
        for your phone number. We want to make sure you are not a robot! And we
        also want to avoid fake Buddies accounts being created which can be used
        for malicious activities and cybercrime – they threaten the Buddies
        network and spoil things for everyone. We use a third party provider for
        account verification who will keep your phone number for up to 90 days
        for fraud identification purposes. We collect and use your phone number
        on the basis of our legitimate interests identified in section 11 below
        and for the prevention of fraud.
      </p>
      <p>
        It is your responsibility to ensure that your account details are kept
        up to date. If your phone number changes, please ensure that you update
        this in your account.
      </p>
      <p>
        To prevent abuse of the app/site, Buddies uses automated decisions and
        moderators to block accounts as part of its moderation procedures. In
        order to do this, we check accounts and messages for content that
        indicates breaches of our Terms and Conditions of Use. This is done
        through a combination of automated systems and our team of moderators.
        If an account or message meets certain criteria that demonstrate that
        the Terms and Conditions of Use are likely to have been breached, the
        relevant account will automatically be blocked. All users of accounts
        that have been blocked will be notified that their account has been
        blocked and affected users can contact Buddies to contest the decision.
      </p>
      <p>
        Buddies also uses automated decisions to prevent fraudulent payment
        transactions being processed as part of its anti-fraud procedures. In
        order to do this, our systems check payment transactions for behavior
        that indicates breaches of our Terms and Conditions of Use. If a
        transaction meets certain criteria that demonstrate that the Terms and
        Conditions of Use are likely to have been breached and the transaction
        is likely to be fraudulent, the relevant transaction may automatically
        be blocked. Where a transaction is blocked, the user will be notified
        that their transaction cannot be processed and affected users can
        contact Buddies to contest the decision.
      </p>
      <p>
        Please note that if your account is blocked, any account(s) that you
        have on our other group platforms/applications may also be blocked as
        part of our anti-spam and anti-fraud procedures.
      </p>
      <p>
        If you decide to purchase any of our premium services, we will process
        your payment information and retain this securely for the prevention of
        fraud and for audit/tax purposes.
      </p>
      <p>
        We process some limited data (demographics and information that you have
        optionally provided us with via Buddies Badges) to drive targeted
        advertising in our legitimate interest, via promo cards and ads on our
        App. We also process limited data (device identifiers) and share this
        with third party advertising networks which host our ads. You can stop
        targeted advertising within the “Contact & FAQ” page accessible via your
        profile. If you opt-out of targeted advertising you will still see
        adverts though they will be less relevant to you. Through your device’s
        security settings you also have the option to prevent or limit device
        identifiers being shared with third party advertisers and what use is
        made of the device identifiers. If you would like more information about
        this practice and to know your choices about not having this information
        used by these companies, please visit this page.
      </p>
      <p>
        Finally, we want to keep in touch with you to make sure you know about
        the great promotions and offers we have available. If you’ve told us
        it’s OK, we will use your email address and phone number to send you
        information on these. You can withdraw this consent at any time via
        Settings in the App.
      </p>
      <T.H4>Geolocation Information</T.H4>
      <p>
        If you turn these features on, when you use your cell phone, we will
        collect information about WiFi access points as well as other location
        information about your longitude and latitude and may save your device's
        coordinates to offer certain features to you. This information helps us
        identify your physical location and we may use it to personalize the App
        and make it easier for you to interact with other Users, by enabling the
        information to be displayed and shared with other members choosing to
        view “nearby” posts.
      </p>
      <p>
        If you have enabled location services, but wish to turn it off, you can
        do so by the following methods:
      </p>
      <p>iPhone app — settings, privacy, location services, Buddies</p>
      <p>Android — settings, location, Buddies, permissions, location</p>
      <T.H4>Log and Usage Data</T.H4>
      <p>
        We keep your personal information only as long as we need it for
        legitimate business purposes and as permitted by applicable law.
      </p>
      <p>
        In practice, we delete or anonymize your information upon deletion of
        your account (following the safety retention window), unless:
      </p>
      <p>
        we must keep it to comply with applicable law (for instance, some
        “traffic data” is kept for one year to comply with statutory data
        retention obligations); we must keep it to evidence our compliance with
        applicable law (for instance, records of consents to our Terms, Privacy
        Policy and other similar consents are kept for five years); there is an
        outstanding issue, claim or dispute requiring us to keep the relevant
        information until it is resolved; or the information must be kept for
        our legitimate business interests, such as fraud prevention and
        enhancing users' safety and security. For example, information may need
        to be kept to prevent a user who was banned for unsafe behavior or
        security incidents from opening a new account. Where Buddies uses
        machine learning, for example, to help us detect and prevent fraudulent
        card transactions, and to detect and prevent spam communications on the
        App (as explained above), we may need to keep personal information for a
        longer period than the retention periods explained above, to enable the
        machine learning to work properly. Where this is the case, we always
        seek to minimize the amount of personal information that is used and we
        ensure that it is ring-fenced and kept securely from other user personal
        information. We regularly review the period for which personal
        information is required for machine learning purposes and delete any
        identifiable information when it is no longer required.
      </p>
      <T.H4>Device Information</T.H4>
      <p>
        We may collect information about your device when you use the App
        including the unique device identifier, device model, operating system,
        and MAC address. In addition, if you permit us to do so, the App may
        access your device's address book solely in order to add someone to your
        contacts.
      </p>
      <T.H4>Links</T.H4>
      <p>
        We may keep track of how you interact with links available on Buddies
        including third party services and clients by redirecting clicks or
        through other means. We may share aggregate click statistics such as how
        many times a particular link was clicked on.
      </p>
      <T.H4>Cookies</T.H4>
      <p>
        The cookies we collect enable us to learn how people interact with
        Buddies, which in turn helps us make a better product for you! Cookies
        store information about your website visits and can recognize you and
        your preferences each time you visit the Buddies site. They help us to
        provide a better service to you!
      </p>
      <p>
        Buddies’ use of cookies and local storage devices is basically related
        to the performance of Buddies’ site, such as analytics that help us
        determine how our site is performing and ensuring a smooth and
        trouble-free experience for our members and visitors.
      </p>
      <p>
        Below is a table to explain Buddies’ cookie functions and why they’re
        used.
      </p>
      <p>
        If for any reason you decide that you do not want all of your Buddies
        activities to be stored you may set your browser and mobile settings to
        block cookies and local storage devices, but please remember that if you
        do so, you may not be able to access all of the features Buddies offers!
      </p>
      <p>We use the following types of cookies:</p>
      <p>
        Cookie Functions Cookie Purposes Analytics and research We use Google
        Analytics to collect information about how visitors use the site. The
        cookies collect information in an anonymous form, including the number
        of visitors to the App, where visitors have come from and the pages they
        visited. For more information about Google's Privacy Policy, please
        visit http://www.google.com/intl/en/policies/. Performance We need to
        use certain cookies and save data to your local storage devices to
        ensure our members have the best possible experience. They assist with
        your navigation of our site, ensuring pages load quickly and respond
        promptly to your requests – no one likes snail pace technology! Our use
        of cookies and local storage devices, including the specific cookie
        names, may change over time, but will generally fall into the above
        categories. We will notify you of any important changes to our use of
        cookies and local storage devices. Please visit this page regularly so
        that you are aware of any changes.
      </p>
      <p>
        If you would like to know more about these clever and calorie free
        cookies, including flash cookies/local storage devices, the following
        websites provide useful information: www.allaboutcookies.org
        www.youronlinechoices.eu
        http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
      </p>
      <T.H4>2. USE OF INFORMATION</T.H4>
      <p>
        Our main goal is to ensure your experience on Buddies is an enjoyable
        one and you don't end up getting stung! In order to deliver an enjoyable
        experience to you, we may use your Registration and other information
        to:
      </p>
      <p>
        offer you our services and features; contact you with information about
        the App (e.g., updates and new offerings); personalize the App and the
        content we deliver to you; conduct research and analytics about how you
        use and interact with the App; resolve disputes between you and other
        Users; and investigate fraud, protect our legal rights, and to enforce
        our Terms & Conditions.
      </p>
      <T.H4>3. DISCLOSURE OF INFORMATION</T.H4>
      <p>
        Our policy is to not disclose your Registration Information, except in
        the limited circumstances described here:
      </p>
      <p>
        Circumstances where data may be disclosed Disclosed data Your Consent –
        If you consent, we may share or disclose your Registration Information,
        such as when you use a third party web client or application to access
        your Account. This could include all data, including all CCPA Categories
        listed above Service Providers - We engage certain trusted third parties
        to perform functions and provide services to us. We may share your
        Registration Information with these third parties, but only for the
        purposes of performing these functions and providing such services e.g.
        information technology companies (hardware and software) which provide
        services to support our products and help us with information security
        matters. This could include all data, including all CCPA Categories
        listed above
      </p>
      <p>
        Moderators – To monitor activity on the apps and approve content. Name
        and user registration details, profile information, content of messages
        and photographs (CCPA Categories A, B, C, E, and H) Payment Processing
        and Telecommunications Companies – To facilitate payments for our
        premium services. Cardholder name, cardholder address, card number,
        payment amount, transaction date/time (CCPA Categories A, B, and D) Law
        and Harm - As we mentioned in the Terms & Conditions, we think it is
        very important that all Buddies behave whilst using the App. We will
        cooperate with all third parties to enforce their intellectual property
        or other rights. We will also cooperate with law enforcement enquiries
        from within or outside your country of residence where we are required
        to by law, where there is an investigation into alleged criminal
        behaviour or to protect the vital interests of a person. This may
        include preserving or disclosing any of your information, including your
        Registration Information, if we believe in good faith that it is
        necessary to comply with a law or regulation, or when we believe that
        disclosure is necessary to comply with a judicial proceeding, court
        order, or legal request; to protect the safety of any person; to address
        fraud, security or technical issues e.g. through anti-spam providers to
        protect the service from criminal activity or to protect our rights or
        property or those of third parties. In such cases we may raise or waive
        any legal objection or right available to us. This could include any
        personal data that Buddies holds about you, depending on the nature of
        the request or the issue that we are dealing with, including all CCPA
        Categories listed above
      </p>
      <p>
        Business Transfers -In the event that Buddies or any of our affiliates
        undergoes a business transition or change of ownership, such as a
        merger, acquisition by another company, re-organisation, or sale of all
        or a portion of its assets, or in the event of insolvency or
        administration, we may be required to disclose your personal data. This
        could include all personal data that Buddies holds about you, including
        all CCPA Categories listed above
      </p>
      <p>
        Advertising Partners – To serve targeted advertisements to users. Device
        ID, geolocation data, demographic data (CCPA Categories C, F, and G)
      </p>
      <p>
        Anti-Spam and Anti-Fraud -Your data may be shared with other Buddies
        group companies, for example, to block accounts and suspected fraudulent
        payment transactions as part of our anti-spam and anti-fraud procedures.
        Email address, phone number, IP address and IP session information,
        social network ID, username, user agent string, and transaction and
        payment data (CCPA Categories B, F and D). Aggregated Information – We
        may share aggregated information with third parties that includes your
        personal data (but which doesn’t identify you directly) together with
        other information including log data for industry analysis and
        demographic profiling. You may opt-out of receiving marketing messages
        by using the opt-out mechanisms and links provided in each email.
      </p>
      <p>
        We ensure these parties must adhere to strict data protection and
        confidentiality provisions that are consistent with this Policy.
        Measures are taken to ensure that the data shared is non-attributable to
        the greatest extent possible.
      </p>
      <p>
        Buddies does not sell your data and has not sold your personal data in
        the previous 12 months.
      </p>
      <T.H4>4. WHAT OTHERS MAY SEE ABOUT YOU</T.H4>
      <p>
        We think our Buddies are awesome, and we want you to share how awesome
        you are with the world, so we have built certain features to enable
        this. Our App is designed to make it easier for you to connect with
        other Users and to interact with them. If you register or log in to the
        App using your Facebook account, you are authorizing us to access
        certain Facebook account information, including information you make
        available via Facebook, your friends list, current location and those
        friends you have in common with other Buddies. You also understand that
        your Buddies profile and other information you make available via the
        App, including information you provide directly or indirectly through
        Facebook (i.e., your Facebook photos, your name, age, approximate
        location, friends you have in common with other Buddies and other
        profile information), may be viewed and shared by Buddies with
        individuals who may or may not be Users or via third party applications
        in such Buddies’ discretion. For example, a User may recommend you as a
        match to his or her Facebook friends by sharing your picture, regardless
        of whether such friend(s) is also a User of the App. You might share
        information like the photos and messages you send or upload and any
        metadata provided with those messages, along with any comments or
        information you provide in connection with your photos.
      </p>
      <p>
        When using Buddies, you should assume that anything you post or submit
        on the App may be publicly-viewable and accessible, both by Users of the
        App and non-Users of the App. We want our Buddies to be careful about
        posting information that will eventually be made public.
      </p>
      <p>
        If you log in to or access Buddies through your Facebook profile,
        remember to log off Facebook when you leave a device in order to prevent
        other users from accessing your account.
      </p>
      <T.H4>5. MODIFYING YOUR REGISTRATION INFORMATION</T.H4>
      <p>
        You may access or modify the Registration Information you provided at
        any time or permanently delete your Account.
      </p>
      <p>
        When your Account is deactivated, we take reasonable efforts to make
        sure it is no longer viewable on the App. For up to 30 days it is still
        possible to restore your Account if it was accidentally or wrongfully
        deactivated. After 30 days, we begin the process of deleting your
        Account from our systems. We are not responsible for any information,
        pictures, comments, or other content that is deleted from our systems
        resulting from the deactivation of your Account.
      </p>
      <p>
        To prevent abuse and/or misuse of Buddies by a User following
        termination or deletion of a profile/Account we shall retain such
        information as we deem in our sole discretion may be necessary to ensure
        that User does not open a new Account and profile in breach of our Terms
        and Conditions of Use and to ensure compliance with all laws and
        regulations.
      </p>
      <p>
        Warning: Even after you remove information from your profile or delete
        your Account, copies of that information may still be viewable and/or
        accessed to the extent such information has been previously shared with
        others, or copied or stored by other Users or to the extent such
        information has been shared with search engines. We cannot control this,
        nor do we accept any liability for this. If you have given third party
        applications or websites access to your personal information they may
        retain such information to the extent permitted under their terms of
        service or privacy policies.
      </p>
      <p>
        Removed and deleted information may persist in backup copies for up to
        30 days to enable restoration, but will not be available to others in
        the meantime.
      </p>
      <T.H4>6. OUR POLICY TOWARDS AGE</T.H4>
      <p>
        Although we want as many people as possible to enjoy our creation, you
        have to be at least 18 years old to use the App - sorry kids, we know
        Buddies is cool, but you'll have to come back when you're old enough!
      </p>
      <p>
        Buddies does not knowingly collect any information about or market to
        children, minors or anyone under the age of 18. If you are less than 18
        years old, we request that you do not submit information to us. If we
        become aware that a child, minor or anyone under the age of 18 has
        registered with us and provided us with personal information, we will
        take steps to terminate that person's registration and delete their
        Registration Information from Buddies. If we do delete a profile because
        you violated our no children rules, we may retain your email and IP
        address to ensure that you do not try to get around our rules by
        creating a new profile.
      </p>
      <T.H4>7. CHANGES TO THIS POLICY</T.H4>
      <p>
        As Buddies evolves, we may revise this Privacy Policy from time to time.
        The most current version of the policy will govern our use of your
        information and will always be at Buddies.com/privacy/. If we make a
        change to this policy that, in our sole discretion, is material, we will
        notify you, for example, via an email to the email associated with your
        Account or by posting a notice within Buddies. By continuing to access
        or use the App after those changes become effective, you agree to be
        bound by the revised Privacy Policy.
      </p>
      <T.H4>8. SECURITY</T.H4>
      <p>
        Here at Buddies we pride ourselves on taking commercially reasonable
        security measures to help protect your information against loss, misuse
        and unauthorized access, or disclosure. We use reasonable security
        measures to safeguard the confidentiality of your personal information
        such as secured servers using firewalls.
      </p>
      <p>
        Unfortunately no website or Internet transmission is ever completely
        100% secure and even we cannot guarantee that unauthorized access,
        hacking, data loss or other breaches will never occur, but here are some
        handy tips to help keep your data secure:
      </p>
      <p>
        Please make sure you log out of your Account after use as you never know
        who may stumble onto your Account! Please don't share your social media
        password with anyone else! Change your social media password
        periodically.
      </p>
      <p>
        If you ever think someone has had access to your social media password,
        please report it to the relevant social media platform and change your
        password immediately. We cannot guarantee the security of your personal
        data while it is being transmitted to our site and any transmission is
        at your own risk.
      </p>
      <p>
        WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR
        IMPLIED, WITH RESPECT TO ANY BREACHES OF SECURITY, DAMAGE TO YOUR
        DEVICE, OR ANY LOSS OR UNAUTHORIZED USE OF YOUR REGISTRATION INFORMATION
        OR OTHER DATA.
      </p>
      <T.H4>9. THIRD PARTY ACCOUNTS</T.H4>
      <p>
        If you have registered with Buddies using a third party social media
        platform, such as Facebook, your Buddies profile will be available to
        all Users of Buddies whether via our App or our partners' websites.
      </p>
      <p>
        Remember that when you register with a third party, you are also giving
        them personal information, so we recommend that you read their privacy
        policies as Buddies does not control how they use their information.
      </p>
      <p>
        If you have registered on one of these third party websites, such as
        Facebook, or you have verified your Account via a third party website,
        we may connect your Buddies profile with your profile on such website.
        We may do this by way of new products and applications which we
        introduce from time to time, including new products powered by our group
        companies.
      </p>
      <p>
        If you have created a Buddies profile through a third party website and
        you do not want to link your Buddies profile to your profile on the
        third party website, Buddies cannot amend these settings. Please visit
        the application settings on your third party website profile and follow
        the instructions to remove the Buddies access permissions.
      </p>
      <p>
        We only use the APIs, OAuth Tokens, Widgets, or other means provided by
        the applicable Social Media Account to integrate your Social Media
        Account with our App. As such, we only receive the limited information
        that each Social Media Account permits to be transferred.
      </p>
      <T.H4>10. YOUR UK AND EU RIGHTS</T.H4>
      <p>
        Under UK and EU law, you have the right to lodge a complaint with data
        protection regulators, and the Information Commissioners’ Office (ICO)
        is the UK’s lead regulator. You can find out how to raise a concern with
        the ICO by visiting their website at www.ico.org.uk. If you’re within
        the EU, you may also get in touch with your local Data Protection
        Regulator who may liaise with the ICO on your behalf.
      </p>
      <p>
        You have a number of rights under European Data Protection law as
        detailed below. These rights apply to all of our users:
      </p>
      <p>
        Right to be informed: what personal data an organisation is processing
        and why (we provide this information to you in this Privacy Policy).
        Right of access: you can request a copy of your data. Right of
        rectification: if the data held is inaccurate, you have the right to
        have it corrected. Right to erasure: you have the right to have your
        data deleted in certain circumstances. Right to restrict processing: in
        limited circumstances, you have the right to request that processing is
        stopped but the data retained. Right to data portability: you can
        request a copy of your data in a machine-readable form that can be
        transferred to another provider. Right to object: in certain
        circumstances (including where data is processed on the basis of
        legitimate interests or for the purposes of marketing) you may object to
        that processing. Rights related to automated decision making including
        profiling: there are several rights in this area where processing
        carried out on a solely automated basis results in a decision which has
        legal or significant effects for the individual. In these circumstances
        your rights include the right to ensure that there is human intervention
        in the decision-making process. Buddies has designated a Data Protection
        Officer and they can be reached by emailing the.buddies.app@gmail.com
      </p>
      <p>
        If you want to exercise any of your rights listed above please visit our
        Feedback Page or email us at the.buddies.app@gmail.com. You can also
        contact us by post at the address above.
      </p>
      <p>
        How does Buddies protect my personal data? Buddies has implemented
        appropriate security measures to protect and prevent the loss, misuse,
        and alteration of the information under our control, including your
        personal data. Our technical experts at Buddies work hard to ensure your
        secure use of our site.
      </p>
      <p>
        While we take reasonable precautions against possible security breaches
        of our website, member database and records, no website or Internet
        transmission is completely secure and we cannot guarantee that
        unauthorized access, hacking, data loss, or other breaches will never
        occur. We urge you to take steps to keep your personal data safe
        (including your password) and to log out of your account after use. If
        you lose your password or give it out, or another service provider that
        you use suffers a data breach and you have used the same credentials
        with that service provider as you have with Buddies, your personal data
        may be compromised. If that happens, please report it to Support via our
        Feedback Page.
      </p>
      <p>
        Where is my personal data kept? We want you to be able to access Buddies
        wherever you happen to be in the world. To enable us to provide that
        service, we operate a global network of servers including in the US,
        Hong Kong, Czech Republic and Russia. The hardware is located in
        third-party data centres but is owned by the Buddies group. Data
        collected by advertising partners may also be held outside the European
        Economic Area. We ensure that the data is adequately protected by
        ensuring that valid, legal mechanisms are in place such as: EU approved
        model clauses (which can be found here), US privacy shield certification
        (more information can be found here – please note that Buddies is not
        Privacy Shield certified but we may pass information to third party
        service providers or advertising partners who are based in the US and
        who are Privacy Shield certified); and implementing robust contractual
        standards. If you want more information relating to the nature of the
        safeguards we have in place, please email the.buddies.app@gmail.com.
      </p>
      <p>
        Does Buddies collect any other personal data about me? If you contact
        our Customer Support team via our Feedback Page, we will receive your
        email address, and may track your IP address, as well as the information
        you send to us to help resolve your query. We will keep records of our
        communications with you, including any complaints that we receive from
        you about other users (and from other users about you) for 2 years after
        deletion of your account.
      </p>
      <p>
        Does Buddies use my personal data for any other purpose? We may use
        material that you post on the open access areas of Buddies in
        advertising and promotional materials on our partner sites and partner
        products. We believe these uses are in line with our legitimate
        interests in enabling us to improve our site and better tailor your
        online experience to meet your needs. If we intend to do this, we will
        contact you for your prior approval.
      </p>
      <p>
        From time to time we may run surveys for research purposes and we may
        contact you to find out if you would like to take part. We may also
        contact you to find out if you would like to take part in marketing
        campaigns. Such surveys and marketing campaigns are optional and more
        information will be provided at the point of contact. We may also
        contact you for feedback purposes. If you do not wish to be contacted to
        take part in a survey or marketing campaign, please contact our Customer
        Support Team via Feedback Page.
      </p>
      <p>
        We use your personal data to resolve disputes, troubleshoot problems and
        enforce our Terms and Conditions of Use.
      </p>
      <p>
        Under EU and UK data protection laws, we are only permitted to use your
        data when we have a lawful basis to do so. The table below provides an
        overview of the legal bases that we rely on to use your data. Where the
        legal basis is consent, you can withdraw consent at any time. Where the
        legal basis is legitimate interests, you have a right to object to our
        use of your data. We explain in the relevant sections in this Policy how
        you can withdraw consent or opt-out of certain data uses (where
        applicable).
      </p>
      <p>
        To verify your identity and prevent fraud and to ensure the safety and
        security of Users Phone number (CCPA Category B) You provide this
        information to us Legitimate interests – it is in our legitimate
        interests to ensure that accounts are not set up fraudulently and to
        safeguard Users of the site To take payment for premium services Payment
        card details (CCPA Categories B and D) You provide this information to
        us Contractual necessity and legitimate interests – we have a legitimate
        interest in receiving payment for our premium services To send you
        marketing information about our offers and services Email address and
        mobile phone number (CCPA Category B) You provide this information to us
        Legitimate interests – it is in our legitimate interests to promote our
        products and services
      </p>
      <p>
        To show “nearby” location information to you and other users of the App
        Wifi access points and location data when you use the App (CCPA Category
        G) We obtain this information from the device that you use to access the
        service Legitimate interests – it is in our legitimate interests to
        provide this functionality as part of the services To carry out research
        and analysis to help us improve the App Log and usage data, including IP
        address, browser type, referring domain, pages accessed, mobile carrier
        and search terms (CCPA Category F) We obtain this information from the
        device that you use to access the service Legitimate interests – it is
        in our interests to analyse the way in which Users are accessing and
        using our services so that we can further develop the App and improve
        the service
      </p>
      <p>
        To respond to correspondence and queries that you submit to us,
        including social media queries Email address and IP address, social
        media name, phone number (CCPA Categories B and F) You provide your
        email address, social media name and phone number to us when you contact
        us and we obtain your IP address from the device that you use to contact
        us Legitimate interests – it is in our legitimate interests to respond
        to your queries to ensure that we provide a good service to Users and
        troubleshoot problems
      </p>
      <p>
        To block accounts as part of our anti-spam procedures Email address,
        phone number, IP address and IP session information, social network ID,
        username, user agent string (CCPA Categories B and F) You provide your
        email address, phone number and username to us. We obtain the other
        information from the device that you use to access the service
        Legitimate interests – it is in our legitimate interests to prevent
        unauthorised behaviour and to maintain the safety and security of our
        services
      </p>
      <p>
        To enable users to create their Buddies profile and log into the app via
        Facebook Data from Facebook, including email address, name and profile
        picture, date of birth, friends who use the app, pages liked, location
        and photos (CCPA Categories A, B, C, and H) We obtain this information
        from Facebook Legitimate interests – it is in our legitimate interests
        to facilitate access to our services
      </p>
      <p>
        To block payment transactions as part of our anti-fraud procedures Name,
        IP address, email address, mobile number, cardholder name, payments
        received, type of payment, user ID, country (CCPA Categories, A, B, and
        D) You provide your name, email address, mobile number and card details
        to us. We obtain your IP address from the device that you use to contact
        us. We obtain your payment information from your interactions with our
        service Legitimate interests – it is in our legitimate interests to
        prevent fraudulent transactions and to maintain the security of our
        services To serve promo cards and advertisements on the App Demographic,
        location data, email address (CCPA Categories A, B, C and G) We obtain
        demographic data from you, and location data from the device that you
        use to access the service. You provide your email address to us
        Legitimate interests – it is in our legitimate interests to target
        advertisements so that users see relevant advertisements and to allow us
        to generate income from advertising revenue To serve advertisements on
        third party networks Device identifiers (IDFA and GAID) (CCPA Category
        F) We obtain this information from the device that you use to access the
        service Legitimate interests – it is in our legitimate interests to
        serve advertisements and to promote our products
      </p>
      <p>
        To contact you in order to run surveys for research purposes and to
        obtain feedback, and to find out if you want to take part in marketing
        campaigns Email address and mobile phone number (CCPA Category B) You
        provide this information to us Legitimate interests – it is in our
        legitimate interests to carry out research so that we can further
        develop the app and improve the service
      </p>
      <p>
        To enable video and audio call functions, and the sending of images and
        video Video and audio call usage data, images and video (CCPA Categories
        F and H) We obtain this information from the device that you use to
        access the service Legitimate interests – it is in our legitimate
        interests to provide these functionalities as part of the services To
        defend legal claims, protect legal rights and to protect people from
        harm This could include any information that is relevant to the issue
        This information may be obtained directly from you, from your device or
        from third parties, depending on the information involved Legitimate
        interests – it is in our legitimate interests to protect our legal
        rights, defend legal claims and to protect our users and third parties
        from harm
      </p>
      <T.H4>11. ABOUT US</T.H4>
      <p>
        Your access to the App, as well as this Privacy Policy are governed and
        interpreted by the laws of the State of Germany, other than such laws,
        rules, regulations and case law that would result in the application of
        the laws of a jurisdiction other than the State of Germany. By using the
        App, you are consenting to the exclusive jurisdiction of the courts of
        the United States and the State of Germany. You agree that such courts
        shall have in personam jurisdiction and venue and waive any objection
        based on inconvenient forum. You agree that you will not file or
        participate in a class action against us. In the event there is a
        discrepancy between this English language version and any translated
        copies of the Policy, the English version shall prevail.
      </p>
      <p>Thanks for making it till here! :)</p>
      <p>
        Effective date This Privacy Policy was last updated on 5 August 2020.
      </p>
    </PageContent>
  )
}
