const defaultUserPic =
  'https://i1.wp.com/afa.org.sg/wp-content/uploads/2014/05/icon-user-default-copy.png?resize=300%2C300&ssl=1'

export const testUsers = [
  {
    displayName: 'Angie',
    age: '25',
    accountCreatedOn: '21-08-2021_12-46-08',
    accountLastLogin: 'undefined',
    birthday: '01-02-1973',
    currentLocation: {
      city: 'Paris',
      country: 'country',
      lat: 0,
      lng: 0,
      locality: '',
    },
    currentLocationLastUpdated: '14-06-2021, 07:41:22',
    email: 'kaplanism@gmail.com',
    gender: 'undefined',
    interests: { focusInterests: ['Array(3)'], moreInterests: ['Array(1)'] },
    notifications: {
      data: 'user-did-not-allow-notifications',
      lastTimeChecked: '14-06-2021, 07:41:22',
      type: 'expo',
    },
    order: -1623645682535,
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/buddies-production-e6f71.appspot.com/o/profilePictures%2Fkaplanism%40gmail.com-0KmRBt2jfqMokW2F0RMHkuMaLlF214-06-2021%2C%2007%3A41%3A22.jpeg?alt=media&token=e4f55c98-e794-420a-9dda-8ea1c0a9a608',
    profileHasBeenApproved: false,
    profileInformation: {
      description: '',
      education: '',
      profession: '',
      socialMedia: '',
    },
    registrationProgress: {
      Birthday: 'done',
      Location: 'done',
      Name: 'done',
      PhotoUpload: 'done',
      SelectInterests: 'done',
    },
    uid: '0KmRBt2jfqMokW2F0RMHkuMaLlF2',
  },

  {
    displayName: 'Mike',
    age: '25',
    accountCreatedOn: '21-08-2021_12-46-08',
    accountLastLogin: 'undefined',
    birthday: '01-02-1973',
    currentLocation: {
      city: 'Berlin',
      country: 'country',
      lat: 0,
      lng: 0,
      locality: '',
    },
    currentLocationLastUpdated: '14-06-2021, 07:41:22',
    email: 'kaplanism@gmail.com',
    gender: 'undefined',
    interests: { focusInterests: ['Array(3)'], moreInterests: ['Array(1)'] },
    notifications: {
      data: 'user-did-not-allow-notifications',
      lastTimeChecked: '14-06-2021, 07:41:22',
      type: 'expo',
    },
    order: -1623645682535,
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/buddies-production-e6f71.appspot.com/o/profilePictures%2Fkaplanism%40gmail.com-0KmRBt2jfqMokW2F0RMHkuMaLlF214-06-2021%2C%2007%3A41%3A22.jpeg?alt=media&token=e4f55c98-e794-420a-9dda-8ea1c0a9a608',
    profileHasBeenApproved: true,
    profileInformation: {
      description: '',
      education: '',
      profession: '',
      socialMedia: '',
    },
    registrationProgress: {
      Birthday: 'done',
      Location: 'done',
      Name: 'done',
      PhotoUpload: 'done',
      SelectInterests: 'done',
    },
    uid: '0KmRBt2jfqMokW2F0RMHkuMaLlF2',
  },
  {
    displayName: 'Mike',
    photoURL: defaultUserPic,
    age: '25',
    accountCreatedOn: '21-08-2021_12-46-08',
    accountLastLogin: 'undefined',
    birthday: '01-02-1973',
    currentLocation: {
      city: 'London',
      country: 'country',
      lat: 0,
      lng: 0,
      locality: '',
    },
    currentLocationLastUpdated: '14-06-2021, 07:41:22',
    email: 'kaplanism@gmail.com',
    gender: 'undefined',
    interests: { focusInterests: ['Array(3)'], moreInterests: ['Array(1)'] },
    notifications: {
      data: 'user-did-not-allow-notifications',
      lastTimeChecked: '14-06-2021, 07:41:22',
      type: 'expo',
    },
    order: -1623645682535,
    profileHasBeenApproved: false,
    profileInformation: {
      description: '',
      education: '',
      profession: '',
      socialMedia: '',
    },
    registrationProgress: {
      Birthday: 'done',
      Location: 'done',
      Name: 'done',
      PhotoUpload: 'done',
      SelectInterests: 'done',
    },
    uid: '0KmRBt2jfqMokW2F0RMHkuMaLlF2',
  },
]
